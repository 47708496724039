import {QuickFactIcon} from '../../../classes/destination-edit-page.model';



export const IconsList: QuickFactIcon[] = [
  {
    Id: 61791,
    Url: './assets/img/icons/facts/lang-language.png'
  },
  {
    Id: 6185,
    Url: './assets/img/icons/facts/currencies.png'
  },
  {
    Id: 6182,
    Url: './assets/img/icons/facts/distance.png'
  },
  {
    Id: 6180,
    Url: './assets/img/icons/facts/sust-ecology.png'
  },
  {
    Id: 6183,
    Url: './assets/img/icons/facts/taxi.png'
  },
  {
    Id: 6184,
    Url: './assets/img/icons/facts/transportation-bus.png'
  },
  {
    Id: 6187,
    Url: './assets/img/icons/facts/information_icon.png'
  },
  {
    Id: 6186,
    Url: './assets/img/icons/facts/map_icon.png'
  }
];
